import request from '@axios'
import axios from 'axios'

export const goToLogin = params => (
  new Promise((resolve, reject) => {
    let res = null
    if (params.username === 'admin' && params.password === 'Arkira@1') {
      res = {
        "data": {
          "status": true,
          "code": 0,
          "msg": "success",
          "data": {
            "username": "13501101388",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IjEzNTAxMTAxMzg4IiwiZXhwIjoxNzMyODY0ODY4fQ.9OtqaV5PMi0ha8F9-0TISbAc7z9OjAMo5D8nSw8JhmA"
          }
        },
        "status": 200,
        "statusText": "OK",
        "headers": {
          "access-control-allow-credentials": "true",
          "access-control-allow-headers": "*",
          "access-control-allow-methods": "GET, POST, PUT, DELETE, OPTIONS",
          "access-control-allow-origin": "*",
          "access-control-allow-private-network": "true",
          "connection": "close",
          "content-length": "224",
          "content-type": "application/json",
          "date": "Thu, 14 Nov 2024 07:21:08 GMT",
          "server": "nginx",
          "via": "kong/2.2.1",
          "x-kong-proxy-latency": "0",
          "x-kong-upstream-latency": "362",
          "x-powered-by": "Express"
        },
        "config": {
          "url": "/dataService/admin/v1/user/login",
          "method": "post",
          "data": "{\"username\":\"13501101388\",\"password\":\"Emotibot@1\"}",
          "headers": {
            "Accept": "application/json, text/plain, */*",
            "Content-Type": "application/json"
          },
          "transformRequest": [
            null
          ],
          "transformResponse": [
            null
          ],
          "timeout": 0,
          "xsrfCookieName": "XSRF-TOKEN",
          "xsrfHeaderName": "X-XSRF-TOKEN",
          "maxContentLength": -1,
          "maxBodyLength": -1,
          "transitional": {
            "silentJSONParsing": true,
            "forcedJSONParsing": true,
            "clarifyTimeoutError": false
          }
        },
        "request": {}
      }
    } else {
      res = {
          "data": {
              "status": false,
              "code": 10005,
              "msg": "用户名或密码错误"
          },
          "status": 200,
          "statusText": "OK",
          "headers": {
              "access-control-allow-credentials": "true",
              "access-control-allow-headers": "*",
              "access-control-allow-methods": "GET, POST, PUT, DELETE, OPTIONS",
              "access-control-allow-origin": "*",
              "access-control-allow-private-network": "true",
              "connection": "close",
              "content-length": "62",
              "content-type": "application/json",
              "date": "Thu, 14 Nov 2024 07:23:37 GMT",
              "server": "nginx",
              "via": "kong/2.2.1",
              "x-kong-proxy-latency": "0",
              "x-kong-upstream-latency": "361",
              "x-powered-by": "Express"
          },
          "config": {
              "url": "/dataService/admin/v1/user/login",
              "method": "post",
              "data": "{\"username\":\"13501101388\",\"password\":\"Emotibot@11\"}",
              "headers": {
                  "Accept": "application/json, text/plain, */*",
                  "Content-Type": "application/json"
              },
              "transformRequest": [
                  null
              ],
              "transformResponse": [
                  null
              ],
              "timeout": 0,
              "xsrfCookieName": "XSRF-TOKEN",
              "xsrfHeaderName": "X-XSRF-TOKEN",
              "maxContentLength": -1,
              "maxBodyLength": -1,
              "transitional": {
                  "silentJSONParsing": true,
                  "forcedJSONParsing": true,
                  "clarifyTimeoutError": false
              }
          },
          "request": {}
      }
    }
    resolve(res.data)
    
    axios.post('/dataService/admin/v1/user/login', params).then(res => {
      console.log('res', res)
      resolve(res.data)
    }).catch(err => {
      console.log('eee', err)
      reject({ code: err.response.status, message: err.response.statusText })
    })
  })
)

export const getEnvs = () => new Promise((resolve, reject) => {
  if (sessionStorage.getItem('envs')) {
    resolve(JSON.parse(sessionStorage.getItem('envs') || '{}'))
  } else {
    axios.get('/botService/v1/ui/envs').then(res => {
      if (res.status === 200 && res?.data.status === 0) {
        sessionStorage.setItem('envs', JSON.stringify(res.data.data))
        resolve(res.data.data)
      }
    })
  }
})
export const fetchRobotList = params => (
  new Promise((resolve, reject) => {
    request.post('/botService/v1/robots/page', params).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject({ code: err.response.status, message: err.response.statusText })
    })
  })
)

export const getEnterpriseListApi = () => (
  new Promise((resolve, reject) => {
    console.log(11, request.get)
    request.get('/botService/v1/tenants/').then(res => {
      if (res.data && (res.data.code === 200 || res.data.code === 0)) {
        resolve(res.data)
      } else {
        reject(res.data ? { code: res.data.code, message: res.data.message } : { code: res.status, message: res.statusText })
      }
    }).catch(err => {
      console.log('err', err)
      reject({ code: err.response.status, message: err.response.statusText })
    })
  })
)
export const getRobotListApi = enterprise => (
  new Promise((resolve, reject) => {
    request.get(`/botService/v1/tenant/robots/${enterprise}`).then(res => {
      if (res.data && (res.data.code === 200 || res.data.code === 0)) {
        resolve(res.data)
      } else {
        reject(res.data ? { code: res.data.code, message: res.data.message } : { code: res.status, message: res.statusText })
      }
    }).catch(err => {
      reject({ code: err.response.status, message: err.response.statusText })
    })
  })
)
