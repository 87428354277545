<script>
import { downloadFile } from '@/plugins/api/modules/task'
import { useUtils } from '@core/libs/i18n'
import { mdiAccountOutline, mdiCalendar, mdiCellphone, mdiRobotOutline } from '@mdi/js'
import { reactive, ref } from '@vue/composition-api'

export default {
  setup(props, { emit }) {
    const { t } = useUtils()
    const menu = ref(false)
    const menu2 = ref(false)
    const firstName = ref('')
    const email = ref('')
    const mobile = ref()
    const password = ref()
    const checkbox = ref(false)
    const queryParams = reactive({
      start_date: null,
      end_date: null,
      user_id: '',
      mobile: '',
      app_id: '',
    })

    const exportChat = () => {
      const params = {
        ...queryParams,
      }
      params.start_date = params.start_date.split('-').join('')
      params.end_date = params.end_date.split('-').join('')
      downloadFile(params)
    }
    const reset = () => {
      queryParams.start_date = null
      queryParams.end_date = null
      queryParams.user_id = ''
      queryParams.mobile = ''
      queryParams.app_id = ''
    }

    return {
      t,
      menu,
      menu2,
      firstName,
      email,
      mobile,
      password,
      checkbox,
      queryParams,
      exportChat,
      reset,
      icons: {
        mdiCalendar,
        mdiAccountOutline,
        mdiCellphone,
        mdiRobotOutline,
      },
    }
  },
}
</script>

<template>
  <v-form>
    <v-menu
      ref="menu"
      v-model="menu.value"
      :close-on-content-click="false"
      :return-value.sync="queryParams.start_date"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="queryParams.start_date"
          :label="t('system.startDate')"
          :prepend-inner-icon="icons.mdiCalendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="queryParams.start_date"
        no-title
        scrollable
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="menu.value = false"
        >
          取消
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.menu.save(queryParams.start_date)"
        >
          确定
        </v-btn>
      </v-date-picker>
    </v-menu>

    <v-menu
      ref="menu2"
      v-model="menu2.value"
      :close-on-content-click="false"
      :return-value.sync="queryParams.end_date"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="queryParams.end_date"
          :label="t('system.endDate')"
          :prepend-inner-icon="icons.mdiCalendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="queryParams.end_date"
        no-title
        scrollable
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="menu2.value = false"
        >
          取消
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.menu2.save(queryParams.end_date)"
        >
          确定
        </v-btn>
      </v-date-picker>
    </v-menu>

    <v-text-field
      v-model="queryParams.user_id"
      :prepend-inner-icon="icons.mdiAccountOutline"
      :label="t('system.userId')"
    />

    <v-text-field
      v-model="queryParams.mobile"
      :prepend-inner-icon="icons.mdiCellphone"
      :label="t('system.mobile')"
      type="number"
      placeholder="Number"
    />

    <v-text-field
      v-model="queryParams.app_id"
      :prepend-inner-icon="icons.mdiRobotOutline"
      :label="t('system.appId')"
    />

    <v-btn
      color="primary"
      @click="exportChat"
    >
      {{ t('system.export') }}
    </v-btn>

    <v-btn
      outlined
      class="mx-2"
      @click="reset"
    >
      {{ t('system.reset') }}
    </v-btn>
  </v-form>
</template>
